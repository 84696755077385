import React, { useEffect, useRef } from "react"
import {
  Flowmap,
  Renderer,
  Geometry,
  Program,
  Texture,
  Mesh,
  Vec2,
  Vec4,
} from "ogl"

const FlowMapImage = ({ imagePublicURL, size, justify, flipMouse }) => {
  const { width, height } = size
  const imageContainer = useRef(null)
  useEffect(() => {
    const vertex = /* glsl */ `
  attribute vec2 uv;
  attribute vec2 position;
  varying vec2 vUv;
  void main() {
      vUv = uv;
      gl_Position = vec4(position, 0, 1);
  }
  `
    const fragment = /* glsl */ `
  precision highp float;
  precision highp int;
  uniform sampler2D texture;
  uniform sampler2D tFlow;
  uniform float uTime;
  varying vec2 vUv;
  uniform vec4 res;
  
  void main() {
      // R and G values are velocity in the x and y direction
      // B value is the velocity length
      vec3 flow = texture2D(tFlow, vUv).rgb;
      // Use flow to adjust the uv lookup of a texture
      vec2 uv = .5 * gl_FragCoord.xy / res.xy ;
      uv += flow.xy * 0.05;
  
      gl_FragColor = texture2D(texture, uv);
  }
  `

    const renderer = new Renderer({
      dpr: 2,
      alpha: true,
      premultiplyAlpha: false,
    })
    const gl = renderer.gl
    const canvas = imageContainer.current.appendChild(gl.canvas)

    // Variable inputs to control flowmap
    let aspect = 1
    const mouse = new Vec2(-1)
    const velocity = new Vec2()
    const imageAspectRatio = width / height
    let imgSize = [
      imageContainer.current.clientWidth,
      imageContainer.current.clientWidth / imageAspectRatio,
    ]
    const greaterDimension = Math.max(imgSize[0], imgSize[1])
    const falloff = 0.22 - greaterDimension / 10000
    const flowmap = new Flowmap(gl, { falloff, dissipation: 0.9 })
    // Triangle that includes -1 to 1 range for 'position', and 0 to 1 range for 'uv'.
    const geometry = new Geometry(gl, {
      position: { size: 2, data: new Float32Array([-1, -1, 3, -1, -1, 3]) },
      uv: { size: 2, data: new Float32Array([0, 0, 2, 0, 0, 2]) },
    })
    const texture = new Texture(gl, {
      minFilter: gl.LINEAR,
      magFilter: gl.LINEAR,
      premultiplyAlpha: true,
    })
    const img = new Image()
    img.src = imagePublicURL
    img.width = imgSize[0] * 2
    img.height = imgSize[1] * 2
    img.onload = () => (texture.image = img)
    let a1, a2
    // make image size like css "contain"
    if (imgSize[1] / imgSize[0] < imageAspectRatio) {
      a1 = 1
      a2 = imgSize[1] / imgSize[0] / imageAspectRatio
    } else {
      a1 = (imgSize[0] / imgSize[1]) * imageAspectRatio
      a2 = 1
    }
    const program = new Program(gl, {
      vertex,
      fragment,
      uniforms: {
        uTime: { value: 0 },
        texture: { value: texture },
        res: {
          value: new Vec4(imgSize[0], imgSize[1], a1, a2),
        },
        // Note that the uniform is applied without using an object and value property
        // This is because the class alternates this texture between two render targets
        // and updates the value property after each render.
        tFlow: flowmap.uniform,
      },
      transparent: true,
    })

    const mesh = new Mesh(gl, { geometry, program })
    // Create handlers to get mouse position and velocity
    const isTouchCapable = "ontouchstart" in window
    if (isTouchCapable) {
      window.addEventListener("touchstart", updateMouse, false)
      window.addEventListener("touchmove", updateMouse, false)
    } else {
      window.addEventListener("mousemove", updateMouse, false)
    }
    let lastTime
    const lastMouse = new Vec2()

    function resize() {
      renderer.setSize(imgSize[0], imgSize[1])
      aspect = imgSize[0] / imgSize[1]
    }
    window.addEventListener("resize", resize, false)
    resize()

    function updateMouse(e) {
      if (e.changedTouches && e.changedTouches.length) {
        e.x = e.changedTouches[0].pageX
        e.y = e.changedTouches[0].pageY
      }
      const positionX = e.x - canvas.getBoundingClientRect().x
      const positionY = e.y - canvas.getBoundingClientRect().y
      /* 
          Get mouse value in 0 to 1 range, with y flipped 
          and x flipped based on props 
        */
      mouse.set(
        flipMouse === "x"
          ? 1.0 - positionX / gl.renderer.width
          : positionX / gl.renderer.width,
        1.0 - positionY / gl.renderer.height
      )
      // Calculate velocity
      if (!lastTime) {
        // First frame
        lastTime = performance.now()
        lastMouse.set(positionX, positionY)
      }

      const deltaX = positionX - lastMouse.x
      const deltaY = positionY - lastMouse.y

      lastMouse.set(positionX, positionY)

      let time = performance.now()

      // Avoid dividing by 0
      let delta = Math.max(14, time - lastTime)
      lastTime = time
      velocity.x = deltaX / delta
      velocity.y = deltaY / delta
      // Flag update to prevent hanging velocity values when not moving
      velocity.needsUpdate = true
    }
    requestAnimationFrame(update)
    function update() {
      requestAnimationFrame(update)
      // Reset velocity when mouse not moving
      if (!velocity.needsUpdate) {
        mouse.set(-1)
        velocity.set(0)
      }
      velocity.needsUpdate = false
      // Update flowmap inputs
      flowmap.aspect = aspect
      flowmap.mouse.copy(mouse)
      // Ease velocity input, slower when fading out
      flowmap.velocity.lerp(velocity, velocity.len ? 0.5 : 0.1)
      flowmap.update()
      renderer.render({ scene: mesh })
      gl.clearColor(0, 0, 0, 0)
    }
  }, [imagePublicURL, width, height, flipMouse])

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        justifyContent: justify === "end" ? "flex-end" : "flex-start",
        marginBottom: "5%",
      }}
      ref={imageContainer}
    ></div>
  )
}

export default FlowMapImage
